<template>
  <div class="page-container-renewal">
    <div>
      <div class="page-content-renewal title-box-policy">
        <div
          class="d-flex flex-column flex-md-row align-items-center justify-content-between"
        >
          <h1 class="text-white text-center text-md-left">
            <i class="mdi mdi-checkbox-multiple-marked"></i> Apólices Emitidas
          </h1>
          <div class="d-flex ml-auto align-items-center">
            <div
              class="d-flex flex-wrap justify-content-center justify-content-md-end align-items-center mb-4"
            >
              <button
                v-if="
                  $can.any(['policy-store', 'policy-update', 'policy-edit'])
                "
                @click="redirect('create-edit-policy')"
                class="ml-auto btn btn-outline-white"
              >
                <i class="fa fa-plus-circle"></i>
                <span class="d-none d-md-inline"> Inserir apólice emitida</span>
              </button>
              <button
                v-if="$can('policy-import')"
                @click="openImportPoliciesModal()"
                class="btn btn-outline-white ml-3"
              >
                <i class="far fa-file-excel"></i>
                <span class="d-none d-md-inline"> Importar apólices</span>
              </button>
              <button
                v-if="$can('policy-export')"
                :disabled="loadingExport"
                @click="downloadReport()"
                class="btn btn-outline-white ml-3"
              >
                <i v-if="!loadingExport" class="fas fa-file-export"></i>
                <b-spinner v-if="loadingExport" small></b-spinner>
                <span class="d-none d-md-inline"> Exportar apólices</span>
              </button>
              <button
                type="button"
                class="btn ml-3 btn-outline-white"
                style="border-radius: 5px 0px 0px 5px"
                v-b-modal.modal-filter
              >
                <i class="fa fa-filter mr-2"></i>
                <span class="d-none d-md-inline-block mr-2">Filtros</span>
              </button>
              <button
                type="button"
                class="button btn btn-outline-white"
                v-b-toggle.collapse-2
                @click="cleanFilter"
                style="border-radius: 0px 5px 5px 0px"
              >
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-view">
      <!-- Loading Overlay -->
      <div
        v-if="loading"
        class="overlay d-flex align-items-center justify-content-center"
      >
        <div>
          <div class="d-flex justify-content-center mb-2">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div class="d-flex align-items-center">
            <strong>Carregando</strong>
          </div>
        </div>
      </div>

      <!-- Tabela de Apólices -->
      <div
        class="gray-table table-responsive table-container-padding"
         style="margin-bottom: 50px; overflow-x: auto;"
      >
        <table
          class="custom-table no-wrap"
          role="table"
          aria-busy="false"
          aria-colcount="9"
          style="width: 96.4% !important;"
        >
          <thead>
            <tr>
              <th style="width: 80px">ID</th>
              <th>TIPO DE SEGURO</th>
              <th>SEGURADORA</th>
              <th>IS</th>
              <th>TOMADOR</th>
              <th>SEGURADO</th>
              <th>PRÊMIO</th>
              <th>VENCIMENTO</th>
              <th class="text-center">STATUS</th>
              <th class="">VENCE EM</th>
              <th class="text-center action-column-custom">AÇÃO</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr v-for="(item, index) in items" :key="index" role="row">
              <td>{{ item.number }}</td>
              <td data-column-title="TIPO DE SEGURO" class="text-truncate">
                {{ item.insurance_type_id }}
              </td>
              <td data-column-title="SEGURADORA">
                <span v-if="item.no_logo">{{ translateInsurerIdentifierTypes[item.insurer] }}</span>
                <img v-else :src="item.icon" class="insurance-logo" @error="imageLoadError(index)" />
              </td>
              <td data-column-title="IS" style="white-space: nowrap">
                {{ formatCurrency(item.contract_value) }}
              </td>
              <td data-column-title="TOMADOR" class="text-truncate">
                {{ truncateText(item.policy_holder_name) }}
              </td>
              <td data-column-title="SEGURADO" class="text-truncate">
                {{ truncateText(item.insured_name) }}
              </td>
              <td data-column-title="PRÊMIO">
                {{ formatCurrency(item.insurance_premium) }}
              </td>
              <td data-column-title="VENCIMENTO">
                {{ formatDateLocal(item.validity_expiration) }}
              </td>
              <td data-column-title="STATUS" class="text-center">
                <span
                  :class="
                    classStatusQuotation(
                      (item.renovations[0] &&
                        item.renovations[0].status_id == 15) ||
                        (item.renovations[0] &&
                          item.renovations[0].status_id == 18)
                        ? item.renovations[0].status_id
                        : item.status
                    )
                  "
                  style="
                    min-width: 130px;
                    min-height: 27px;
                    padding-bottom: 1px;
                  "
                >
                  {{
                    (item.renovations[0] &&
                      item.renovations[0].status_id == 15) ||
                    (item.renovations[0] && item.renovations[0].status_id == 18)
                      ? "Em renovação"
                      : getEnumKeyByEnumValue(
                          translateStatusQuotationTypes,
                          item.status
                        )
                  }}
                </span>
              </td>
              <td data-column-title="VENCE EM" class="">
                <span
                  v-html="addHtmlClass(item)"
                  v-if="
                    item.status != translateStatusQuotationTypes['Renovada']
                  "
                ></span>
              </td>
              <td
                data-column-title="Ação"
                class="actions-policy-icons text-right action-column-custom"
              >
                <button
                  v-if="
                    $can.any(['policy-store', 'policy-update', 'policy-edit'])
                  "
                  class="btn btn-action-gray"
                  @click="redirect('create-edit-policy', { policyId: item.id })"
                  v-b-tooltip.hover
                  title="Editar"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <button
                  v-if="$can('policy-show')"
                  class="btn btn-action-gray"
                  @click="redirect('details-policy', { policyId: item.id })"
                  v-b-tooltip.hover
                  title="Visualizar"
                >
                  <i class="fas fa-eye"></i>
                </button>
                <b-dropdown variant="ellipsis" size="sm" left no-caret class="b-dropdown-custom-color" menu-class="custom-dropdown-menu-items">
                  <template #button-content>
                    <i class="fa fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item
                    v-if="
                      $can.any(['claim-store', 'claim-update', 'claim-edit']) &&
                      item.status != translateStatusQuotationTypes['Cancelada']
                    "
                    @click="redirect('create-claim', { policyId: item.id })"
                    v-b-tooltip.hover
                    title="Reportar Sinistro"
                  >
                    <i class="fa fa-fw mdi mdi-shield-alert" style="margin-left: -3px;"></i> Reportar
                    Sinistro
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="
                      $can.any([
                        'endorsement-store',
                        'endorsement-update',
                        'endorsement-edit',
                      ])
                    "
                    @click="
                      redirect('create-endorsement', {
                        policyId: item.id,
                      })
                    "
                    v-b-tooltip.hover
                    data-toggle="tooltip"
                    title="Criar Endosso"
                  >
                    <i class="far fa-calendar-check"></i> Criar Endosso
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="
                      $can.any(['policy-store']) &&
                      item.renovations.length === 0 &&
                      item.status != translateStatusQuotationTypes['Cancelada']
                    "
                    @click="showModalCreate(item)"
                    v-b-tooltip.hover
                    title="Renovar apólice"
                  >
                    <i class="fas fa-sync-alt"></i> Renovar apólice
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="$can('policy-delete')"
                    @click="showModalDestroy(item)"
                    data-toggle="tooltip"
                    v-b-tooltip.hover
                    title="Apagar"
                  >
                    <i class="fas fa-trash"></i> Apagar
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <b-pagination
            @page-click="pageChanged"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            class="my-3 mb-4"
          ></b-pagination>
        </div>
      </div>
    </div>

    <ImportPoliciesModal
      v-model="showImportPolicies"
      @policiesImported="getPolicies()"
    />
    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        size="sm"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title>
          {{ title }}
        </template>

        <div class="card card-termo-uso mt-1 mb-5">
          <p class="my-4">{{ deletion_data }}</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              @click="destroyPolicy()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#modal-cnpj"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>
    <div class="modal">
      <b-modal
        ref="modalFilter"
        id="modal-filter"
        size="lg"
        class="mt-4"
        centered
      >
        <br />
        <template #modal-title> Filtrar Apólices </template>

        <form class="mb-2">
          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label>Número FINN</label>
              <b-form-input
                class="mb-3"
                size="lg"
                id="name-input"
                v-model="filtersTable.number"
                required
              ></b-form-input>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <label>Tomador</label>
              <b-form-input
                class="mb-3"
                size="lg"
                id="name-input"
                v-model="filtersTable.policy_holder"
                list="policyHolderList"
                required
              ></b-form-input>
              <datalist id="policyHolderList">
                <option
                  v-for="(policyHolder, index) in filteredPolicyHolders"
                  :value="policyHolder.name"
                  :key="index"
                >
                  {{ policyHolder.name }} ({{
                    formatCnpj(policyHolder.company_cnpj)
                  }})
                </option>
              </datalist>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label>Número da apólice</label>
              <b-form-input
                class="mb-3"
                size="lg"
                id="name-input"
                v-model="filtersTable.external_number"
                required
              ></b-form-input>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <label>Segurado</label>
              <b-form-input
                type="text"
                v-model="filtersTable.insured"
                list="insuredList"
                class="mb-3"
                size="lg"
                id="name-input"
                required
              ></b-form-input>
            </div>
          </div>

          <datalist id="insuredList">
            <option
              v-for="(insured, index) in filteredInsureds"
              :value="insured.name"
              :key="index"
            >
              <div v-if="insured && insured.document">
                {{ insured.name }} ({{ formatCnpj(insured.document) }})
              </div>
              <div v-else>
                {{ insured.name }}
              </div>
            </option>
          </datalist>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label>Processo</label>
              <b-form-input
                class="mb-3"
                size="lg"
                id="name-input"
                v-model="filtersTable.process"
                required
              ></b-form-input>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <label>Prazo para vencer</label>
              <b-form-select
                v-model="filtersTable.expiration_date"
                class="mb-3"
              >
                <b-form-select-option value="">Sem Filtro</b-form-select-option>
                <b-form-select-option value="1">Vencida</b-form-select-option>
                <b-form-select-option value="2"
                  >Vencendo em 30 dias</b-form-select-option
                >
                <b-form-select-option value="3"
                  >Vencendo em 60 dias</b-form-select-option
                >
                <b-form-select-option value="4"
                  >Vencendo em mais de 60 dias</b-form-select-option
                >
              </b-form-select>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6 mb-3">
              <label>Seguradora</label>
              <b-form-select v-model="filtersTable.insurer" class="mb-3">
                <b-form-select-option value="">Sem Filtro</b-form-select-option>
                <b-form-select-option
                  v-for="(value, index) in list_insurers"
                  :value="index"
                  :key="index"
                >
                  {{ value }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label>Modalidade</label>
              <b-form-select
                v-model="filtersTable.insurance_category"
                class="mb-3"
              >
                <b-form-select-option value="">Sem Filtro</b-form-select-option>
                <b-form-select-option
                  v-for="(value, index) in list_insurance_categories"
                  :value="value.id"
                  :key="index"
                >
                  {{ value.name }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <label>Status</label>
              <b-form-select v-model="filtersTable.status" class="mb-3">
                <b-form-select-option value="">Sem Filtro</b-form-select-option>
                <b-form-select-option value="8">Ativa</b-form-select-option>
                <b-form-select-option value="9">Cancelada</b-form-select-option>
                <b-form-select-option value="10"
                  >Endossada</b-form-select-option
                >
                <b-form-select-option value="15"
                  >Em renovação</b-form-select-option
                >
                <b-form-select-option value="17">Renovada</b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <div class="pr-3 py-3">
                <b-form-checkbox v-model="filtersTable.traditional_guarantee"
                  >Garantia Tradicional</b-form-checkbox
                >
              </div>
              <div class="py-2">
                <b-form-checkbox v-model="filtersTable.appeal_guarantee"
                  >Garantia RE</b-form-checkbox
                >
              </div>
            </div>
          </div>

          <label>Vencimento</label>
          <div id="filtros" class="row">
            <div class="col-12 col-lg-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">de</span>
                </div>
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  locale="pt-BR"
                  nav-button-variant="white"
                  v-model="filtersTable.start_date"
                  menu-class="w-100"
                  calendar-width="100%"
                  :show-decade-nav="false"
                  :hide-header="true"
                  placeholder="dd/mm/aaaa"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">até</span>
                </div>
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  locale="pt-BR"
                  nav-button-variant="white"
                  v-model="filtersTable.end_date"
                  menu-class="w-100"
                  calendar-width="100%"
                  :show-decade-nav="false"
                  :hide-header="true"
                  placeholder="dd/mm/aaaa"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </form>

        <template #modal-footer>
          <b-button @click="filterPolicies" variant="outline-primary"
            >Filtrar <i class="fa fa-search"></i
          ></b-button>
        </template>
      </b-modal>
    </div>

    <!-- Modal criação de renovação -->
    <div class="modal">
      <b-modal
        id="show-modal-create"
        ref="modalCreate"
        size="md"
        class="mt-4"
        hide-footer
        centered
      >
        <template #modal-title>Renovar apólice</template>

        <div v-if="policyRenewSelected">
          <div class="row form">
            <div class="col-12">
              <p>
                Deseja Iniciar a renovação da
                <strong>Apólice nº{{ policyRenewSelected.number }}</strong
                >?
              </p>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Tomador</label>
              <p>
                <strong>{{ policyRenewSelected.policy_holder_name }}</strong>
              </p>
            </div>
            <div class="col-12">
              <label class="dbo-ui">Segurado/Beneficiário</label>
              <p>
                <strong>{{ policyRenewSelected.insured_name }}</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="d-block mt-3">
          <div class="d-flex justify-content-between">
            <button
              @click="$bvModal.hide('show-modal-create')"
              class="btn btn-outline-secondary btn-lg px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i>
              <span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              type="button"
              class="btn btn-primary btn-lg text-uppercase px-4"
              @click="createRenovation()"
            >
              <b-spinner v-if="loading" small></b-spinner> Renovar
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import policyRenovationService from "@/services/policy-renovation";
import numericFormatterMixin from "@/mixins/numericFormatter";
import dateFormatter from "@/mixins/dateFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import uiHelperMixin from "@/mixins/uiHelper";
import tableHelperMixin from "@/mixins/tableHelper";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import insuranceCategoryTecnicTypes from "@/enums/translate/insuranceCategoryTecnicTypes";
import fileDownload from "js-file-download";
import ImportPoliciesModal from "@/components/Policy/ImportPolicies/Index.vue";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import translateInsurerIdentifierTypes from "@/enums/translate/insurerIdentifierTypes";

export default {
  name: "list-policies",
  components: {
    ImportPoliciesModal,
  },
  mixins: [
    numericFormatterMixin,
    dateFormatter,
    commomsHelper,
    uiHelperMixin,
    tableHelperMixin,
  ],
  data: () => ({
    translateInsuranceTypes: translateInsuranceTypes,
    insuranceCategoryTecnicTypes: insuranceCategoryTecnicTypes,
    items: [],
    title: "Excluir apólices",
    deletion_data: "",
    current_deletion: null,
    showImportPolicies: false,
    list_insurers: [],
    list_insurance_categories: [],
    dateNow: new Date(),
    rows: 0,
    loading: false,
    loadingExport: false,
    nameTable: "policies",
    filtersTable: {
      policy_holder: "",
      process: "",
      insured: "",
      insurer: "",
      insurance_category: "",
      expiration_date: "",
      start_date: "",
      end_date: "",
      number: "",
      external_number: "",
      status: "",
      traditional_guarantee: true,
      appeal_guarantee: true,
    },
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateInsurerIdentifierTypes: translateInsurerIdentifierTypes,
    policyRenewSelected: {},
    status: [],
    insureds: [],
    policyHolders: [],
  }),
  created() {
    this.dateNow.setHours(23, 59, 59, 999);

    if (this.$route.query.filter) {
      this.fillFilterForm();
      this.fetchInsuranceData();
      return;
    }

    this.cleanFilter();
    this.fetchInsuranceData();
  },
  methods: {
    getPolicies() {
      this.resetAlert();
      this.loading = true;
      policyService
        .get(this.getFilterForm())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.rows = response.data.total;
          this.formatList(response.data);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);

          this.showError("Não foi possível recuperar as apólices.");
        });
    },
    getInsurers() {
      this.resetAlert();
      policyService
        .getInsurers()
        .then((response) => {
          this.list_insurers = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de seguradoras.");
        });
    },
    getInsuranceCategories() {
      this.resetAlert();
      policyService
        .getInsuranceCategories()
        .then((response) => {
          this.list_insurance_categories = response.data.map((element) => ({
            id: element.id,
            name: element.tecnic_name,
          }));
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a lista de modalidades.");
        });
    },
    addHtmlClass(data) {
      const today = new Date();
      const date1 = new Date(this.formatDateISO(today));
      const date2 = new Date(data.deadline.split(" ")[0]);
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      switch (true) {
        case differenceInDays < 0:
          return '<div class="badge badge-dark rounded-pill badge-custom-pill text-uppercase px-4 py-2 font-12">vencido</div>';

        case differenceInDays > 0 && differenceInDays < 30:
          return (
            '<div class="badge badge-danger rounded-pill badge-custom-pill text-uppercase px-4 py-2 font-12">' +
            differenceInDays +
            " dias</div>"
          );

        case differenceInDays >= 30 && differenceInDays < 60:
          return (
            '<div class="badge badge-warning rounded-pill badge-custom-pill text-white text-uppercase px-4 py-2 font-12">' +
            differenceInDays +
            " dias</div>"
          );

        case differenceInDays >= 60:
          return (
            '<div class="badge badge-success rounded-pill badge-custom-pill text-uppercase px-4 py-2 font-12">' +
            differenceInDays +
            " dias</div>"
          );

        case differenceInDays == 0:
          return '<div class="badge badge-danger rounded-pill badge-custom-pill text-uppercase px-4 py-2 font-12">Vence hoje</div>';
        default:
          return "";
      }
    },
    formatList(data) {
      this.items = data.data.map((element) => ({
        id: element.id,
        number: element.number,
        insurance_type_id: this.getEnumKeyByEnumValue(
          this.insuranceCategoryTecnicTypes,
          element.insurance_category_id
        ),
        contract_value: element.contract_value,
        policy_holder_name: element.policy_holder_name,
        insured_name: element.insured_name,
        insurance_premium: element.insurance_premium,
        validity_expiration: element.validity_expiration,
        deadline: element.validity_expiration,
        insurer: element.insurer_selected,
        action: element.id,
        status: element.status,
        renovations: element.renovations,
        icon: element.icon,
      }));
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    downloadReport() {
      this.loadingExport = true;
      policyService
        .downloadReport(this.getFilterForm())
        .then((response) => {
          this.loadingExport = false;
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          fileDownload(response.data, "policies-export.xlsx");
          policyService.deleteFilePolicy();
        })
        .catch(() => {
          this.showError("Não foi possível realizar o download deste arquivo.");
        });
    },
    openImportPoliciesModal() {
      this.showImportPolicies = true;
    },
    showModalDestroy(data) {
      this.current_deletion = data.action;
      this.deletion_data =
        "Deseja realmente excluir os dados de " +
        data.policy_holder_name +
        " ?";
      this.$refs.showModal.show();
    },
    destroyPolicy() {
      this.loading = true;
      this.$refs.showModal.hide();
      policyService
        .destroy(this.current_deletion)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.getPolicies();
        })
        .catch(() => {
          this.showError("Não foi possível remover a cotação.");
        })
        .finally(() => {
          this.current_deletion = null;
          this.checkPolice = true;
          this.loading = false;
        });
    },
    filterPolicies() {
      this.setFilterForm();
      this.getPolicies();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.filtersTable = {
        policy_holder: "",
        process: "",
        insured: "",
        insurer: "",
        insurance_category: "",
        expiration_date: "",
        start_date: "",
        end_date: "",
        number: "",
        external_number: "",
        status: "",
        traditional_guarantee: true,
        appeal_guarantee: true,
      };
      this.setFilterForm();
      this.getPolicies();
    },
    sortingTableChanged(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.setFilterForm();
      this.getPolicies();
    },
    pageChanged(bvEvent, pageNumber) {
      this.currentPage = pageNumber;
      this.setFilterForm();
      this.getPolicies();
    },
    showModalCreate(policy) {
      if (policy) {
        this.policyRenewSelected = policy;
        this.$refs.modalCreate.show();
      }
    },
    createRenovation() {
      if (!this.policyRenewSelected) {
        this.showError("Não foi possível iniciar a renovação.");
        return;
      }

      this.loading = true;
      policyRenovationService
        .store(this.policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.redirect("garantee-data", {
            quotationId: response.data.quotation_id,
          });
        })
        .catch(() => {
          this.showError("Não foi possível remover a cotação.");
        })
        .finally(() => {
          this.policyRenewSelected = {};
          this.loading = false;
          this.$refs.modalCreate.hide();
        });
    },
    getIndexFilterValues() {
      this.loading = true;
      policyService
        .getIndexFilterValues()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.policyHolders = response.data.policy_holders;
          this.insureds = response.data.insureds;
        })
        .catch(() => {
          this.showError("Não foi possível remover a cotação.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchInsuranceData() {
      this.getPolicies();
      this.getInsurers();
      this.getInsuranceCategories();
      this.getIndexFilterValues();
    },
    truncateText(text, maxChar = 28) {
      return text.length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
    imageLoadError(idx) {
      this.$set(this.items[idx], "no_logo", true);
    }
  },
  computed: {
    filteredInsureds() {
      if (this.filtersTable.insured && this.filtersTable.insured.length >= 3) {
        return this.insureds
          .filter(
            (insured) =>
              insured.insured_name &&
              insured.insured_name
                .toLowerCase()
                .includes(this.filtersTable.insured.toLowerCase())
          )
          .map((insured) => ({
            name: insured.insured_name,
            document: insured.insured_document,
          }));
      }
      return [];
    },
    filteredPolicyHolders() {
      if (
        this.filtersTable.policy_holder &&
        this.filtersTable.policy_holder.length >= 3
      ) {
        return this.policyHolders
          .filter(
            (policy_holder) =>
              policy_holder.policy_holder_name &&
              policy_holder.policy_holder_name
                .toLowerCase()
                .includes(this.filtersTable.policy_holder.toLowerCase())
          )
          .map((policy_holder) => ({
            name: policy_holder.policy_holder_name,
            company_cnpj: policy_holder.policy_holder_document,
          }));
      }
      return [];
    },
  },
};
</script>

<style scoped>
</style>